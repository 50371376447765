html {
  padding: 0;
  margin: 0;
  font-size: 16px; }
  html body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0;
    margin: 0;
    background-color: rgba(224, 172, 155, 0.25); }
    html body div.App {
      text-align: center; }
      html body div.App nav.navbarStyle {
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        margin: 0 auto; }
        html body div.App nav.navbarStyle img {
          max-width: 140px; }
      html body div.App img.logoStyle {
        margin: 60px auto 30px; }
      html body div.App div.container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
      html body div.App div.TVSet {
        justify-content: center; }
        html body div.App div.TVSet img {
          width: 80%;
          margin: 1rem 0 0; }
      html body div.App div.Contact {
        max-width: 550px;
        margin: 0; }
        html body div.App div.Contact form {
          width: 80%;
          padding-top: 1.4rem; }
          html body div.App div.Contact form div.formGroup {
            flex-flow: row nowrap;
            margin: 1.4rem 0; }
            html body div.App div.Contact form div.formGroup label {
              width: 25%;
              text-align: start;
              font-size: 1.1rem; }
            html body div.App div.Contact form div.formGroup div {
              width: 75%; }
          html body div.App div.Contact form button.submitButton {
            width: 60%;
            margin: 1rem auto;
            background-color: #75d866;
            border: none;
            font-size: 1.4rem; }
          html body div.App div.Contact form .ContactFormInput {
            background-color: rgba(255, 255, 255, 0.9);
            border: 1px solid rgba(117, 216, 102, 0.52); }
      html body div.App div.Shows {
        max-width: 550px;
        margin: 0;
        flex-direction: column; }
        html body div.App div.Shows div.ShowObject {
          width: 85%;
          margin: 2rem auto; }
        html body div.App div.Shows h4.showsPlaceholderText {
          margin: 3rem 0; }
      html body div.App div.Shop {
        flex-direction: column;
        max-width: 550px;
        margin: auto; }
        html body div.App div.Shop img.shopTitle {
          padding-top: 2rem; }
      html body div.App div.Footer {
        margin: 2rem 0; }
        html body div.App div.Footer img {
          width: 250px; }
      html body div.App div.Thanks {
        padding: 4rem 0; }
        html body div.App div.Thanks h1 {
          margin: 2rem 0; }
        html body div.App div.Thanks h3 {
          margin: 1rem 0; }
      html body div.App img.lineStyle {
        width: 90%;
        margin: 28px auto; }
      html body div.App img.title {
        padding-top: 2rem; }

@media screen and (max-width: 700px) {
  html {
    font-size: 14px; }
    html body div.App {
      padding: 0; }
      html body div.App div.container {
        padding: 0; }
        html body div.App div.container nav.navbarStyle {
          margin: 0; }
          html body div.App div.container nav.navbarStyle a img {
            width: 90%; }
        html body div.App div.container img.title {
          width: 150px; }
        html body div.App div.container img.logoStyle {
          width: 230px;
          margin: 2rem auto; }
        html body div.App div.container div.TVSet {
          margin: 2rem 0; }
        html body div.App div.container div.Shows img#showsLogo {
          width: 120px; }
        html body div.App div.container div.Shows h4.showsPlaceholderText {
          width: 80%;
          margin: 25% auto; }
        html body div.App div.container div.Shop img#shopLogo {
          width: 90px; }
        html body div.App div.container div.Shop img.shootOut {
          width: 90%; }
        html body div.App div.container div.Contact form {
          width: 80%; }
        html body div.App div.container div.Footer {
          margin: 1.6rem; }
          html body div.App div.container div.Footer img {
            width: 200px; }
        html body div.App div.container div.Thanks {
          width: 90%; }
        html body div.App div.container img.lineStyle {
          margin: 1rem 0; } }
